// Here you can add other styles
.sidebar {
  --cui-sidebar-bg: #101827;
  font-size: 12px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.meteor-container {
  position: relative;
  // width: 100%;
  // height: 100vh; /* You can adjust the height as needed */

  width: 11rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.meteor {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: whitesmoke;
  animation: meteorAnimation 5s linear infinite;
}

@keyframes meteorAnimation {
  0% {
    transform: translateX(0) translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateX(100px) translateY(100px);
    opacity: 0;
  }
}
.sidebar-brand {
  background: #101827;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.card {
  border-radius: 0.75rem !important;
  --bs-card-border-radius: 0.75rem !important;
}
.body {
  font-family: 'Times New Roman', Times, serif;
}
.card {
  background-color: white;
  border-radius: 8px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.23); /* Box shadow for the card */
}
.header.header-sticky {
  position: sticky;
  top: 0;
  z-index: 1029;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.23);
}

.card-body {
  .bg-primary,
  .bg-info,
  .bg-warning,
  .bg-danger,
  .bg-secondary,
  .bg-dark,
  .bg-success {
    border-radius: 7px;
    --bs-bg-opacity: 0.9;
  }
}
